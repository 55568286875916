@import "@styles/variables.scss";
@import "@styles/mixins.scss";

.root {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  padding: 0 $offset;
  margin-bottom: 28px;

  @include breakpoint("md") {
    margin-bottom: $offset;
  }
}

.item {
  padding-left: $offset;
  font-family: BebasNeueBook;
  font-size: 12px;
  color: #008f8b;

  &::before {
    content: "";
    position: relative;
    display: block;
    top: 43%;
    left: -9px;
    width: 6px;
    height: 6px;
    border-radius: 3px;
    background-color: #008f8b;
    z-index: 100;
  }

  &__value {
    font-family: BebasNeueBold;
    font-size: 12px;
  }

  @include breakpoint("md") {
    font-size: 16px;

    &__value {
      font-size: 16px;
    }
  }
}
