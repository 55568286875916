@import "@styles/mixins.scss";

.modal {
  align-items: center;
  justify-content: center;
  max-height: 100vh;
  overflow: auto;

  &__content {
    padding: 0 4px;
  }

  @include breakpoint("md") {
    &__content {
      padding: 0;
    }
  }
}

.loader {
  display: flex;
  align-items: center;
  margin: 0 auto;
  justify-content: center;
  height: 100vh; 
}

.root {
  position: relative;
  width: 100%;
  background-color: white;
  padding: 0 1rem;
  max-width: 1400px;
  border-radius: 10px;
  box-shadow: 0 0 5px #9b9b9b;

  &__card {
    width: 100%;
    margin: $offset auto;
    max-width: 900px;
  }

}

.inactive {
  position: relative;
  background-color: rgba(0, 0, 0, 0.5);
  font-family: BebasNeueBold;
  font-size: 2.5rem;
  color: #ffffff;
  border-radius: 9px;
  padding: 70px 150px;
}

.info {
  flex-direction: row;
  align-items: flex-start;
  padding-top: 48px;
  margin-bottom: 8px;

  @include breakpoint("md") {
    margin-bottom: 24px;
  }
}

.main {
  width: 90%;
}
