.root {
  position: relative;

  &__cover {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% + 2rem);
    margin: 0 -1rem;
    border-radius: 9px;
    background-color: rgba(0, 0, 0, 0.5);
    font-family: BebasNeueBold;
    font-size: 2.5rem;
    color: #ffffff;
    text-transform: uppercase;
    z-index: 300;
  }
}
