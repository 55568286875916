.root {
  position: relative;
  width: 100%;
  padding: 0 1rem;
  border: 1px solid #6f6f6f;
  border-radius: 10px;
  box-shadow: 0 0 5px #9b9b9b;
  overflow: hidden;
  transition: .3s all;
    &:hover {
      transform: scale(0.98);
      opacity: .65;
    }
}

.main {
  width: 66%;
}

.interactive {
  cursor: pointer;
}
