@import "@styles/variables.scss";
@import "@styles/mixins.scss";

.root {
  flex-flow: column nowrap;
  justify-content: space-between;

  &__card {
    width: 100%;
    margin-bottom: $offset;
  }

  @include breakpoint("md") {
    flex-flow: row wrap;
    padding: 0 $offset;

    &__card {
      width: 49%;
    }
  }
}
