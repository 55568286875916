@import "@styles/mixins.scss";

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  padding: 25px;
  border: 10px solid rgba(0, 0, 0, 0.7);
  border-radius: 4px;
  background-color: white;

  &__text {
    width: 100%;
    margin-bottom: 1.75em;
    text-align: center;
  }

  &__button {
    width: auto;
    padding: 6px 15px;
    border: 1px solid #3b7808;
    border-radius: 4px;
    background-color: #5cb811;
    background-image: -webkit-linear-gradient(
      top,
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 0)
    );
    box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.5);
    color: #ffffff;
    font-weight: bold;
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.5);
    cursor: pointer;
  }

  @include breakpoint("md") {
    width: 50%;
  }
}

.modal {
  &__appearance {
    top: 5%;
    opacity: 1;
    animation: appearance 0.3s ease-in;
  }

  &__disappearance {
    top: 0;
    opacity: 0;
    animation: disappearance 0.3s ease-out;
  }

  @keyframes appearance {
    0% {
      top: 0;
      opacity: 0;
    }
    90% {
      top: 7%;
      opacity: 0.9;
    }
    100% {
      top: 5%;
      opacity: 1;
    }
  }

  @keyframes disappearance {
    0% {
      top: 5%;
      opacity: 1;
    }

    10% {
      top: 7%;
      opacity: 0.9;
    }

    100% {
      top: 0;
      opacity: 0;
    }
  }
}
