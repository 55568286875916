@import "@styles/mixins.scss";

.root {
  width: 100%;
  margin: 14px 0;
  padding: 20px;
  background-color: #ebebeb;
  border-radius: 10px;

  &__header {
    margin-bottom: 12px;
    font-family: BebasNeueBold;
    font-size: 18px;
    color: #008f8b;
  }

  &__desc {
    max-height: 140px;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
    overflow-y: auto;
  }

  @include breakpoint("md") {
    margin: 28px 0;

    &__header {
      font-size: 20px
    }

    &__desc {
      max-height: 220px;
      font-size: 16px;
      line-height: 20px;
    }
  }
}
