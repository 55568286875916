@import "@styles/variables.scss";
@import "@styles/mixins.scss";

.root {
  width: 100%;
  height: 100%;
  max-width: 1400px;
  display: flex;
  flex-direction: column;
}

.navigate {
  flex-direction: column-reverse;
  gap: 1.5em;
}

.search {
  width: 100%;
  height: 100%;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: $offset;

  &__map {
    width: 100%;
    height: 460px;
    margin-bottom: 16px;
  }

  &__form {
    width: 100%;
  }

  @include breakpoint("md") {
    flex-direction: row;

    &__form {
      width: 40%;
    }

    &__map {
      width: 60%;
      height: 500px;
    }
  }

  @include breakpoint("lg") {
    &__map {
      height: 460px;
    }
  }
}
