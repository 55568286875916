@import "@styles/variables.scss";

.root {
  margin-bottom: 1rem;

  &__header {
    width: 100%;
    height: auto;
    padding-bottom: $offset; 
  }
}

.courses {
  justify-content: space-between;
  width: 100%;
  padding: 0 $offset;

  &__course {
    width: 19%;
    height: auto;
  }

  &__inactive {
    opacity: 0.2
  }
}
