@import "@styles/variables.scss";

.root {
  justify-content: center;
  padding-bottom: 1rem;
}

.anchor {
  padding: 0 $offset;
  cursor: pointer;

  &__icon {
    width: 32px;
    height: 32px;
  }
}
