@import "@styles/variables.scss";
@import "@styles/mixins.scss";

.root {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;
  padding: 10px;
  background-color: #3498db;
  color: #ffffff;
  margin: 0 $offset $offset $offset;

  &__item {
    width: 100%;
    padding: 10px 20px;
    cursor: pointer;
    transition: .3s all;

    &:hover {
      transform: scale(1.1);
    }
  }

  @include breakpoint("md") {
    flex-flow: row wrap;

    &__item {
      width: auto;
      padding: 5px;
    }
  }
}
