.root {
  &__name {
    margin-bottom: 0.2em;
    font-family: BebasNeueBold;
    font-size: 1.8rem;
    line-height: 1.8rem;
    color: #299cd7;
    text-transform: uppercase;
  }

  &__info {
    margin-bottom: 0.2em;
    font-family: BebasNeueBook;
    font-size: 1.6rem;
    line-height: 1.6rem;
    color: #008f8b;
    text-transform: uppercase;
  }
}
