@import "@styles/variables.scss";
@import "@styles/mixins.scss";

.root {
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;
  justify-content: center;
  gap: $offset;

  @include breakpoint("md") {
    margin-bottom: $offset;
  }
}

.item {
  font-family: BebasNeueBook;
  font-size: 12px;
  color: #008f8b;
  display: flex;
  align-items: center;
  gap: 0.5em;
  align-items: start;

  &__value {
    font-family: BebasNeueBold;
    font-size: 12px;
  }
   &__link {
    cursor: pointer;
    transition: .3s all;
    &:hover {
      opacity: .75;
    }
  }

  @include breakpoint("lg") {
    font-size: 20px;

    &__value {
      font-size: 22px;
    }
  }
}
