.root {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 0.4em 1em;
  border: 1px solid #dddddd;
  border-radius: 4px;
  background-color: #e84c3d;

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 20px;
    padding: 2px 6px 3px;
    border: 1px solid #c5c5c5;
    border-radius: 0.25rem;
    font-size: 1rem;
    cursor: pointer;
  }

  &__icon {
    display: flex;
    justify-content: center;
    width: 12px;
    height: 12px;
  }
}
