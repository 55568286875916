.root {
  width: 20px;
  height: 20px;
  cursor: pointer;
  z-index: 100;
}

.cluster {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  border-radius: 50%;
  border: 3px solid red;
  background-color: red;
  box-shadow: 0px 0px 4px 1px rgb(255, 255, 255);
  color: white;
  cursor: pointer;
}
