@import "@styles/mixins.scss";
@import "@styles/variables.scss";

.root {
    position: relative;
    width: 100%;
    background-color: white;
    padding: 20px 0.5rem;
    max-width: 1400px;
    border-radius: 10px;
    box-shadow: 0 0 5px #9b9b9b;
    @include breakpoint("md") {
      padding: 20px 1rem;
    }
  }
  
  .main {
    display: flex;
    flex-direction: column;
    gap: $offset;
    width: 90%;
  }

  .isDetailsAbout {
    margin: 0 auto;
    max-width: 900px;
  }

  .isStudioPage {
    cursor: pointer;
    transition: .3s all;
    &:hover {
      transform: scale(0.98);
     opacity: .65;
    }
  }