@import "@styles/variables.scss";
@import "@styles/mixins.scss";

.root {
  width: 33%;
  height: 50%;
  padding: 0 8px;

  &__avatar {
    position: relative !important;
    max-width: 200px;
    height: auto !important;
    border-radius: 50%;
  }

  @include breakpoint("md") {
    height: 75%;
  }

  @include breakpoint("lg") {
    height: 100%;

    &__avatar {
      max-width: 180px;
    }
  }
}

.container {
  position: relative;
}
