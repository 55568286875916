.root {
  position: absolute;
  bottom: -45px;
  right: -70px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 150px;
  height: 100px;
  padding: 10px 25px;
  transform: rotate(-45deg);
  background-color: hsl(0, 0%, 92%);
  z-index: 10;

  &__text {
    font-family: BebasNeueBold;
    color: #008f8b;
  }
}
