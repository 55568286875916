@import "@styles/mixins.scss";

.root {
  &__name {
    margin-bottom: 0.2em;
    font-family: BebasNeueBold;
    font-size: 24px;
    color: #299cd7;
    text-transform: uppercase;
  }

  &__info {
    margin-bottom: 0.2em;
    font-family: BebasNeueBook;
    font-size: 18px;
    color: #008f8b;
    text-transform: uppercase;
  }

  @include breakpoint("md") {
    &__name {
      font-size: 32px;
    }

    &__info {
      font-size: 24px;
    }
  }
}
