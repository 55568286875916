@import "@styles/variables.scss";

.root {
  justify-content: center;
  padding-bottom: 1rem;

  &__button {
    padding: 0 40px;
    cursor: pointer;
  }

  &__anchor {
    padding: 0 $offset;
    cursor: pointer;
  }
}
