.root {
  padding: 0.5em 1em;
  border: 1px solid #dddddd;
  border-radius: 4px;
}

.form {
  &__title {
    color: #333333;
    font-size: 100%;
    font-weight: bold;
  }

  &__submit {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
  }
}

.line {
  flex-direction: column;
  width: 35%;

  &__label {
    padding: 0;
    margin-top: 8px;
    margin-bottom: 4px;
    color: #333333;
    font-size: 100%;
    font-weight: bold;
  }

  &__textarea {
    width: 100%;
    line-height: 1.6em;
  }
}

.input {
  &__input {
    padding: 0.75em;
    font-size: 1rem;

    &::placeholder {
      color: #333333;
      font-size: 100%;
      font-weight: bold;
      opacity: 0.6;
    }
  }

  &__textarea {
    min-height: 120px;
    padding: 0.75em;
  }
}
