@import "@styles/mixins.scss";

.root {
  padding: 0 $offset;

  &__header {
    margin-bottom: $offset;
  }
}

.field {
  flex-direction: column;
  margin-bottom: 8px;

  &__label {
    padding: 0;
    margin-bottom: 4px;
  }

  @include breakpoint("md") {
    flex-direction: row;
    align-items: center;

    &__label {
      padding: 0 $offset;
      margin-bottom: 0;
    }
  }
}
